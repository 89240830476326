import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Container, Grid, Paper } from '@mui/material';

const UpdatePassword = () => {
  const [email, setEmail] = useState(''); // State for user's email
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to the API endpoint for password reset
      const response = await axios.post('/api/reset-password', {
        email: email,
        newPassword: newPassword
      });

      setMessage(response.data.message);
      setError('');
      setNewPassword('');
    } catch (error) {
      setMessage('');
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError('Failed to reset password. Please try again.');
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: 20, marginTop: 20, marginBottom: 50 }}>
        <Typography variant="h5" gutterBottom>
          Reset Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="New Password"
                type="password"
                variant="outlined"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" style={{ backgroundColor: '#E25F3C' }}>
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </form>
        {message && (
          <Typography variant="body1" style={{ color: 'green', marginTop: 10 }}>
            {message}
          </Typography>
        )}
        {error && (
          <Typography variant="body1" style={{ color: 'red', marginTop: 10 }}>
            {error}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default UpdatePassword;
